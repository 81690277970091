import React from "react"
import Button from "@material-ui/core/Button"
import { Typography } from "@material-ui/core"

const Home = () => {
  return (
    <>
      <Typography>Hello world</Typography>
      <Button>OK</Button>
    </>
  )
}

export default Home
